import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { CONTAINER_MAX_WIDTH } from "../../menu/constants";
import React from "react";

function Contacts() {
  return (
    <Box
      component="section"
      className="md-lg:tw-pb-16 max-md-lg:tw-pb-8"
      id="contacts"
    >
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Typography
          className="md-lg:!tw-text-3xl max-md-lg:!tw-text-2xl !tw-font-semibold !tw-mb-8 max-md-lg:tw-w-full tw-text-center"
          component="h2"
        >
          Є запитання?
          <span className="tw-text-blue-650"> Давайте обговоримо!</span>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md-lg={4}>
            <Box className="tw-flex tw-flex-col tw-items-center">
              <Box className="tw-flex tw-items-center tw-justify-center tw-w-24 tw-h-24 tw-rounded-3xl tw-bg-blue-650 tw-mb-4">
                <Box
                  component="img"
                  src="/img/home-view-images/phone-icon.png"
                  alt="phone-icon"
                />
              </Box>
              <Typography component="p">Телефон (для дзвінків)</Typography>
              <Link
                className="!tw-text-xl !tw-font-bold !tw-text-inherit"
                underline="none"
                href="tel:+38 096 422 42 73"
              >
                +38 096 422 42 73
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md-lg={4}>
            <Box className="tw-flex tw-flex-col tw-items-center">
              <Box className="tw-flex tw-items-center tw-justify-center tw-w-24 tw-h-24 tw-rounded-3xl tw-bg-blue-650 tw-mb-4">
                <Box
                  component="img"
                  src="/img/home-view-images/mail-icon.png"
                  alt="mail-icon"
                />
              </Box>
              <Typography component="p">Email</Typography>
              <Link
                className="!tw-text-xl !tw-font-bold !tw-text-inherit"
                underline="none"
                href="mailto:appetite@appetiteservices.com"
              >
                appetite@appetiteservices.com
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md-lg={4}>
            <Box className="tw-flex tw-flex-col tw-items-center">
              <Box className="tw-flex tw-items-center tw-justify-center tw-w-24 tw-h-24 tw-rounded-3xl tw-bg-blue-650 tw-mb-4">
                <Box
                  component="img"
                  src="/img/home-view-images/phone-icon.png"
                  alt="phone-icon"
                />
              </Box>
              <Typography component="p">
                Телефон (для viber, telegram або whatsapp)
              </Typography>
              <Link
                className="!tw-text-xl !tw-font-bold !tw-text-inherit"
                underline="none"
                target="_blank"
                href="https://t.me/appetiteservices"
              >
                +38 096 422 42 73
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Contacts;
