import React, { useContext, useState } from "react";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { observer } from "mobx-react";
import { TabContext, TabList } from "@mui/lab";
import { StoreContext } from "../../../../../contexts/Store";
import { MenuCategories } from "../menu-categories";
import { Box, Container } from "@mui/material";
import classnames from "classnames";
import { CONTAINER_MAX_WIDTH } from "../../../constants";
import { IntlMessage } from "../../../../../components/util-components";

function MenuSections() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // State
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // Variables
  const { data, isThemeDark, mainColor } = mainStore.menu;
  const sections = Object.values(data?.Sections ?? {})
    .filter((section) => section.section_active)
    .sort((a, b) => a.position - b.position);

  // State
  const [value, setValue] = useState(sections[0]?.id);

  // Functions
  const handleChange = (event, newValue) => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    setValue(newValue);
  };

  if (!sections.length) {
    return (
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Box component="h2" className="tw-text-center">
          <IntlMessage id="menu.sections.notCreated" />
        </Box>
      </Container>
    );
  }

  return (
    <TabContext value={value}>
      <Box
        className={classnames("tw-sticky", "tw-top-0", "tw-z-10", {
          "tw-bg-white": !isThemeDark,
          "tw-bg-neutral-925": isThemeDark,
        })}
      >
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          <TabList
            onChange={handleChange}
            variant="scrollable"
            className={classnames("tw-sticky", "tw-top-0", "tw-z-10", {
              "tw-bg-white": !isThemeDark,
              "tw-bg-neutral-925": isThemeDark,
            })}
            TabIndicatorProps={{
              style: {
                background: mainColor,
              },
            }}
            scrollButtons={false}
            sx={{
              "& .MuiTabs-indicator": {
                transition: "left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              },
            }}
          >
            {sections.map((section) => {
              return (
                <Tab
                  label={section.section_name}
                  key={section.id}
                  value={section.id}
                  style={value === section.id ? { color: mainColor } : {}}
                  className="first:!tw-ml-0 !tw-ml-4 !tw-min-w-0 !tw-px-0 !tw-font-semibold !tw-text-xl"
                  disableFocusRipple
                  disableRipple
                  sx={{
                    color: "text.primary",
                  }}
                />
              );
            })}
          </TabList>
        </Container>
      </Box>
      {sections.map((section) => (
        <TabPanel value={section.id} key={section.id} className="!tw-p-0">
          <MenuCategories
            sectionID={section.id}
            isFirstLoad={isFirstLoad}
            setIsFirstLoad={setIsFirstLoad}
          />
        </TabPanel>
      ))}
    </TabContext>
  );
}

export default observer(MenuSections);
