import { action, makeObservable, observable } from "mobx";

export default class Settings {
  mainStore;

  lang = "uk";

  constructor(mainStore) {
    makeObservable(this, {
      lang: observable,
      setLang: action,
    });

    this.mainStore = mainStore;
  }

  setLang(value) {
    this.lang = value;
  }
}
