import Categories from "./data/Categories";
import Establishments from "./data/Establishments";
import Menu from "./data/Menu";
import Products from "./data/Products";
import Sections from "./data/Sections";
import Subscription from "./data/Subscription";
import Settings from "./data/Settings";

class MainStore {
  categories;
  establishments;
  menu;
  products;
  sections;
  subscription;
  settings;

  constructor() {
    this.categories = new Categories();
    this.establishments = new Establishments(this);
    this.menu = new Menu(this);
    this.products = new Products();
    this.sections = new Sections();
    this.subscription = new Subscription();
    this.settings = new Settings();
  }

  cleanup() {
    this.establishments.cleanup();
    this.menu.cleanup();
  }
}

const mainStore = new MainStore();

export default mainStore;
